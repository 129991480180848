import './TextSearchItem.scss';

import { useIntl, FormattedMessage } from 'react-intl';
import { useState } from "react";

import ym from 'react-yandex-metrika';
import IsPhoneValid from "./IsPhoneValid";

import { PATH } from "../config";

export default function TextSearchItem (props)  {

    const [phone, setPhone] = useState('');
    const [isFormValid, setIsFormValid] = useState(false);
    const [isFormSend, setIsFormSend] = useState(false);

    const Intl = useIntl();
    const showForm = () => {
        return props.form && !isFormSend;
    }
    const caption = () => {
        return isFormSend ? Intl.messages['form_send'] : props.caption;
    }

    function handleSearchTextChange(e) {
        setPhone( e.target.value );
        setIsFormValid(IsPhoneValid( e.target.value ));
    }

    function formSendHandler() {
        let requestParams = {
            name: 'Запрос из поиска',
            phone: phone,
            departure_airport: props.airportFromData.icao,
            arrival_airport: props.airportToData.icao,
            departure_datetime: props.departureDate,
            departure_datetime_back: props.departureBackDate,
        };
        let url = PATH.SEARCH_EMAIL;
        fetch( url, { method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },body: JSON.stringify(requestParams),} )
        setIsFormSend(true);
        ym('reachGoal','CALLBACK');
    }

    return (
            <div className="text-search-item">
                <div className="text-search-item-content">
                    <div className="text-search-item-header">{ props.header }</div>
                    <div className="text-search-item-caption" dangerouslySetInnerHTML={ {__html: caption() } } />
                    { showForm() &&
                        <div className="text-search-item-form">
                            <input className="text-search-item-form-input"
                               type="text"
                               placeholder={ Intl.messages['enter_telephone'] }
                               value= { phone }
                               onChange={ handleSearchTextChange }
                            />
                            { isFormValid &&
                                <div className="text-search-item-form-button" onClick={ () => formSendHandler() }><span><FormattedMessage id='send' /></span></div>
                            }
                            { !isFormValid &&
                                <div className="text-search-item-form-button-disabled"><span><FormattedMessage id='send' /></span></div>
                            }
                        </div>
                    }
                </div>
            </div>
    );
}
