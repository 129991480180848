import { FormattedMessage } from 'react-intl';
import React, { Fragment, useState } from "react";
import Book from "./Book";

import telegramPic from "../assets/telegram.svg";
import whatsappPic from "../assets/whatsapp.svg";
import emailPic from "../assets/email.svg"

export default function InfoLine (props)  {

    const telegramShareLink = 'https://telegram.me/share/url?url=' + window.location.href;
    const whatsappShareLink = 'https://api.whatsapp.com/send/?text=' + window.location.href;
    const mailLink = 'mailto:?body=' + encodeURIComponent(window.location.href);

    return (
        <div className="flightinfo-share">
            <div className="flightinfo-share-title">
                <FormattedMessage id='share'/>:
            </div>
            <div className="flightinfo-share-buttons">
                <a target="_blank" href={ telegramShareLink }>
                <div className="flightinfo-infoline-big-button">
                    <img className="flightinfo-infoline-big-button-image" src={ telegramPic } alt="Telegram share"/>
                </div>
                </a>
                <a target="_blank" href={ whatsappShareLink }>
                    <div className="flightinfo-infoline-big-button">
                        <img className="flightinfo-infoline-big-button-image" src={ whatsappPic } alt="Whatsapp share"/>
                    </div>
                </a>
                <a href={ mailLink }>
                <div className="flightinfo-infoline-big-button">
                    <img className="flightinfo-infoline-big-button-image" src={ emailPic } alt="Email"/>
                </div>
                </a>
            </div>
        </div>
    )
}