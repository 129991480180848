import './Footer.scss';

import { useIntl, FormattedMessage } from 'react-intl';

import { Link } from "react-router-dom";

export default function Footer ( props ) {

    const Intl = useIntl();

    let footerClassName;
    if (props.searchPage) { footerClassName = 'search-footer'; }
    if (props.staticPage) { footerClassName = 'static-footer'; }
    if (props.flightPage || props.searchPage) { footerClassName += ' with-margin'; }

    let leftText;
    if ( props.leftText ) { leftText = props.leftText; } else { leftText = Intl.messages['home_last_text_left']; }

    let  rightText;
    if ( props.rightText ) { rightText = props.rightText; } else { rightText = Intl.messages['home_last_text_right']; }

    let now = new Date();
    let currentYear = now.getFullYear();

    return (
        <footer className={ footerClassName }>
            <div className="about">
                <div className="text_item">
                    <div className="header"><FormattedMessage id='about' /></div>
                    <div className="text"><FormattedMessage id='about_text' /></div>
                    <div className="certificates_mobile">
                        <div className="cert1" />
                        <div className="cert2" />
                    </div>
                    <div className="links">
                        <Link to={ useIntl().messages['currentLocaleLink'] + 'about/' }>
                            <FormattedMessage id='about_link' />
                        </Link>
                    </div>
                    <div className="links">
                        <Link to={ useIntl().messages['currentLocaleLink'] + 'partners/' }>
                            <FormattedMessage id='be_partner' />
                        </Link>
                    </div>
                </div>
                <div className="certificates_desktop">
                    <div className="cert1" />
                    <div className="cert2" />
                </div>
            </div>
            <div className="contacts">
                <p className="text"><a href="tel:+74951208020">+7 495 120-80-20</a></p>
                <p className="text"><a href="mailto:info@najet.ru">info@najet.ru</a></p>
            </div>
            <div className="line" />
            <div className="copyrights"><FormattedMessage id='copyrights' />{ currentYear }</div>
            <div className="last_text">
                <span className="left" dangerouslySetInnerHTML={ {__html: leftText} } />&nbsp;
                <span className="right" dangerouslySetInnerHTML={ {__html: rightText} } />
            </div>
        </footer>
    );
}
