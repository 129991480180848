import './Bblock.scss';

import { FormattedMessage } from 'react-intl';

export default function Bblock()  {
    return (
        <div className="bblock">
            <div className="label"><FormattedMessage id='main_benefit' /></div>
            <div className="caption">
                <div className="header"><FormattedMessage id='benefit1_header' /></div>
                <div className="text"><FormattedMessage id='benefit1_description' /></div>
                <div className="header"><FormattedMessage id='benefit2_header' /></div>
                <div className="text"><FormattedMessage id='benefit2_description' /></div>
            </div>
        </div>
    );
}