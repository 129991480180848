import './RenderSearchResults.scss';
import './FlightInfo.scss';

import React, { useState } from "react";
import { useIntl, FormattedMessage } from "react-intl";

import RenderItem from "./RenderItem";
import TextSearchItem from "./TextSearchItem";
import InfoLine from "./InfoLine";
import Book from "./Book";

export default function RenderSearchResults (props)  {

    let preloaderItems = 5; // Количество айтемов в прелоадере
    let specItemNumber = -1; // Позиция спецпредложения в списке

    let resultsClass;

    const Intl = useIntl();

    // Обработка вида результатов в зависимости от страницы. Результаты поиска, или информация о рейсе
    if ( props.flightId ) {
        if ( props.departureBackDate ) {
            resultsClass = "flightinfo-back-results flightinfo-results results";
        } else {
            resultsClass = "flightinfo-results results";
        }
    } else {
        resultsClass = "results";
    }
    if ( props.emptyLeg ) { resultsClass = resultsClass + ' emptyleg-results'; }

    const [isBookOpen, setIsBookOpen] = useState(false);
    let showBook = isBookOpen;

    function isBookOpenToggle( boolean ) {
        if (boolean) {
            setIsBookOpen(true);
            document.body.style.overflow = "hidden";
        } else {
            setIsBookOpen(false);
            document.body.style.overflow = "scroll";
        }
    }

    if (!props.isLoading &&
        (
            (props.airportFromData && props.airportToData && props.airportsData && props.departureDate) ||
            (props.emptyLeg)
        )
    ) {
        if (!props.flightId) {
            specItemNumber = props.flightsData.cards.length;
        }

        return (
            <div className={ resultsClass } id="results">
                { props.flightsData.cards.map((item, index) => (
                    <React.Fragment key = { item.id }>
                        <RenderItem
                            first = { index === 0 }
                            last = { index + 1 === props.flightsData.cards.length }
                            item = { item }
                            airportFromData = { props.airportFromData }
                            airportToData = { props.airportToData }
                            airportsData = { props.airportsData }
                            departureDate = { props.departureDate }
                            departureBackDate = { props.departureBackDate }
                            flightId = { props.flightId }
                            flightInfo = { props.flightInfo }
                            currency = { props.currency }
                            emptyLeg = { props.emptyLeg }
                        />
                        { specItemNumber === index + 1 && !props.emptyLeg &&
                            <React.Fragment>
                                <TextSearchItem
                                    key = { index }
                                    header={ props.airportFromData.city + ' — ' + props.airportToData.city }
                                    form = { true }
                                    airportFromData = { props.airportFromData }
                                    airportToData = { props.airportToData }
                                    departureDate = { props.departureDate }
                                    departureBackDate = { props.departureBackDate }
                                    caption={ Intl.messages[ 'contact_manager' ] }
                                />
                                <div className="text-item-splitter" />
                            </React.Fragment>
                        }
                    </React.Fragment>
                )) }

                { props.flightsData.cards.length === 0 &&
                    <TextSearchItem
                        header={ props.airportFromData.city + ' — ' + props.airportToData.city }
                        form = { true }
                        airportFromData = { props.airportFromData }
                        airportToData = { props.airportToData }
                        departureDate = { props.departureDate }
                        departureBackDate = { props.departureBackDate }
                        caption={ Intl.messages[ 'not_found' ] }
                    />
                }
                <div className="flightinfo">
                    { showBook &&
                        <Book
                            isBookOpenToggle = { isBookOpenToggle }
                            flightInfo = { props.flightInfo }
                            cardId = { props.cardId }
                        />
                    }
                    <div className="flightinfo-infoline" id="flightinfo-infoline">
                        <div className="flightinfo-infoline-text">
                            <FormattedMessage id='infoline_text'/>
                        </div>
                        <div className="flightinfo-infoline-big-button flightinfo-infoline-order" onClick={() => isBookOpenToggle(true)}>
                            <div className="flightinfo-infoline-big-button-label"><FormattedMessage id='order'/></div>
                        </div>
                        <InfoLine />
                    </div>
                </div>
            </div>
        )
    } else {
        if (props.flightsData && props.flightsData.cards.length !== 0) {
            // console.log( 'Набор данных для рендера 2: '+ props.flightsData.cards );
            return (
                <div className={ resultsClass } id="results">
                    { props.flightsData.cards.map(item => (
                        <RenderItem
                            key = { item.id }
                            isLoading = "true"
                            flightId = { props.flightId }
                            flightInfo = { props.flightInfo }
                            departureBackDate = { props.departureBackDate }
                        />
                    )) }
                </div>
            );
        } else {
            // console.log( 'Набор данных для рендера 3: '+ props.flightsData );
            let key = [];
            for (let i=0;i<preloaderItems;i++) { key.push(i); }
            return (
                <div className={ resultsClass } id="results">
                    { key.map(key => (
                        <RenderItem
                            key = { key }
                            isLoading = "true"
                            flightId = { props.flightId }
                            flightInfo = { props.flightInfo }
                            departureBackDate = { props.departureBackDate }
                        />
                    )) }
                </div>
            );
        }
    }
};
