import './SecondScreen.scss';

import { useState } from "react";
import { useIntl, FormattedMessage } from 'react-intl';
import { Link } from "react-router-dom";
import React from "react";

import ym from 'react-yandex-metrika';

import left_arrow from '../assets/left-arrow.svg';
import right_arrow from '../assets/right-arrow.svg';

import amg_icon from '../assets/sanret.png';
import bysky_icon from '../assets/bysky.png';
import weltall_icon from '../assets/weltall.png';
import tulpar_icon from '../assets/tulpar.png';

import IsPhoneValid from "./IsPhoneValid";
import { PATH } from "../config";

export default function SecondScreen()  {

    const [isCallButtonPressed, setIsCallButtonPressed] = useState(false);
    const [isFormValid, setIsFormValid] = useState(false);
    const [isFormSend, setIsFormSend] = useState(false);
    const [phone, setPhone] = useState('');

    const Intl = useIntl();

    const PARTNERS_TEXTS = [
        {
            name: Intl.messages['amg_name'],
            text: Intl.messages['amg_text'],
            logo: amg_icon,
        },
        {
            name: Intl.messages['bysky_name'],
            text: Intl.messages['bysky_text'],
            logo: bysky_icon,
        },
        {
            name: Intl.messages['weltall_name'],
            text: Intl.messages['weltall_text'],
            logo: weltall_icon,
        },
        {
            name: Intl.messages['tulpar_name'],
            text: Intl.messages['tulpar_text'],
            logo: tulpar_icon,
        },
    ];

    let scrollMoveSmi = 327;
    let scrollMovePartner = 356;

    function formChangeHandler(e) {
        setPhone( e.target.value );
        setIsFormValid(IsPhoneValid( e.target.value ))
    }

    function formSendHandler(){
        if ( !isFormSend ) {
            let url = new URL(PATH.PARTNER_EMAIL);
            let requestParams = {
                name : '',
                contact : phone,
                message : 'Пользователь запросил звонок с главной страницы',
            };
            fetch(url, {
                method : 'POST',
                headers : {
                    'Accept' : 'application/json',
                    'Content-Type' : 'application/json'
                }, body : JSON.stringify(requestParams),
            })
                .then(response => response.json())
                .then(
                    (result) => {
                        console.log(result)
                        setIsCallButtonPressed(false);
                        setIsFormSend(true);
                        ym('reachGoal','CALLBACK');
                    },
                    (error) => {
                        console.log(error)
                    });
        }
    }

    function SmiRightHandler() {
        document.getElementById("smiItems").scrollBy(scrollMoveSmi,0);
    }

    function SmiLeftHandler() {
        document.getElementById("smiItems").scrollBy(-scrollMoveSmi,0);
    }

    function PartnerRightHandler() {
        document.getElementById("partnerItems").scrollBy(scrollMovePartner,0);
    }

    function PartnerLeftHandler() {
        document.getElementById("partnerItems").scrollBy(-scrollMovePartner,0);
    }

    return (
        <div className="second_screen">
            <div className="callbutton">
                { !isCallButtonPressed &&
                    <div className="callbutton-header" onClick={ ()=> setIsCallButtonPressed(true) }>
                        { isFormSend ? <FormattedMessage id='thanks' /> : <FormattedMessage id='request_call' /> }
                    </div>
                }
                { isCallButtonPressed &&
                    <div className="callbutton-form">
                        <input className="callbutton-input"
                               type="text"
                               placeholder={ Intl.messages['enter_telephone'] }
                               value= { phone }
                               onChange={ (e) => formChangeHandler(e) }
                        />
                        { isFormValid &&
                            <div className="callbutton-input-submit" onClick={ () => formSendHandler() }><span><FormattedMessage id='send' /></span></div>
                        }
                    </div>
                }
            </div>
            <div className="smi">
                <div className="top">
                    <div className="number">00</div>
                    <div className="line" />
                </div>
                <div className="caption">
                    <div className="header"><FormattedMessage id='media_about_us' /></div>
                    {/* <div className="navigation">
                        <img src={ left_arrow } onClick={ SmiLeftHandler } alt={ Intl.messages['left'] } />&nbsp;
                        <img src={ right_arrow }  onClick={ SmiRightHandler } alt={ Intl.messages['right'] }/>
                    </div> */}
                </div>
                <div className="items" id="smiItems">
                    <div className={ Intl.messages['media1_logo'] + " item" }>
                        <div>
                            <a href= { Intl.messages['media1_link'] } >
                                <p className="text"><FormattedMessage id='media1_text' /></p>
                                <p className="data"><FormattedMessage id='media1_date' /></p>
                            </a>
                        </div>
                    </div>
                    <div className={ Intl.messages['media3_logo'] + " item" }>
                        <div>
                            <a href= { Intl.messages['media3_link'] } >
                                <p className="text"><FormattedMessage id='media3_text' /></p>
                                <p className="data"><FormattedMessage id='media3_date' /></p>
                            </a>
                        </div>
                    </div>
                    <div className={ Intl.messages['media4_logo'] + " item" }>
                        <div>
                            <a href= { Intl.messages['media4_link'] } >
                                <p className="text"><FormattedMessage id='media4_text' /></p>
                                <p className="data"><FormattedMessage id='media4_date' /></p>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div className="articles">
                <div className="row">
                    <div className="item">
                        <div className="top">
                            <div className="number">01</div>
                            <div className="line" />
                        </div>
                        <div className="header"><FormattedMessage id='destination' /></div>
                        <div>____</div>
                        <div className="text"><FormattedMessage id='destination_text' /></div>
                        <Link to={ useIntl().messages['currentLocaleLink'] + 'restrictions/' }>
                            <div className="link"><FormattedMessage id='destination_link_text' /></div>
                        </Link>
                    </div>
                    <div className="item">
                        <div className="top">
                            <div className="number">03</div>
                            <div className="line" />
                        </div>
                        <div className="header"><FormattedMessage id='how_it_works' /></div>
                        <div>____</div>
                        <div className="text"><FormattedMessage id='how_it_works_text' /></div>
                        <Link to={ useIntl().messages['currentLocaleLink'] + 'howitworks/' }>
                            <div className="link"><FormattedMessage id='how_it_works_link_text' /></div>
                        </Link>
                    </div>
                </div>
                <div className="row">
                    <div className="item">
                        <div className="top">
                            <div className="number">02</div>
                            <div className="line" />
                        </div>
                        <div className="header"><FormattedMessage id='fleet' /></div>
                        <div>____</div>
                        <div className="text"><FormattedMessage id='fleet_text' /></div>
                        <div className="link">
                            <Link to={ useIntl().messages['currentLocaleLink'] + 'fleet/' }>
                                <FormattedMessage id='fleet_link_text' />
                            </Link>
                        </div>
                    </div>
                    <div className="item">
                        <div className="top">
                            <div className="number" id="partners">04</div>
                            <div className="line" />
                        </div>
                        <div className="partner_top">
                            <div className="header"><FormattedMessage id='partners' /></div>
                            <div className="navigation">
                                <img width="24" height="24" src={ left_arrow } onClick={ PartnerLeftHandler } alt={ Intl.messages['left'] } />&nbsp;
                                <img width="24" height="24" src= { right_arrow } onClick={ PartnerRightHandler } alt={ Intl.messages['right'] } />
                            </div>
                        </div>
                        <div className="partner_content" id="partnerItems">
                            { PARTNERS_TEXTS.map((item,index) =>
                                <div className="partner_item" key={ index }>
                                    <div className="logo"><img src={ item.logo } alt="Jet Express Airlines" /></div>
                                    <div className="header">{ item.name }</div>
                                    <div className="text">{ item.text }</div>
                                </div>
                            ) }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
