import './Menu.scss';

import { useIntl, FormattedMessage } from 'react-intl';
import { Link } from "react-router-dom";

export default function Menu (props)  {

    const Intl = useIntl();

    return (
        <div className="header-menu" onClick={ () => props.changeIsMenuOpened(false) } >
            <div className="header-menu-item"><Link to={Intl.messages['currentLocaleLink']}><FormattedMessage id='main' /></Link></div>
            <div className="header-menu-item"><Link to={Intl.messages['currentLocaleLink'] + 'fleet/'}><FormattedMessage id='fleet_link_text' /></Link></div>
            <div className="header-menu-item"><a href={Intl.messages['currentLocaleLink'] + '#partners'}><FormattedMessage id='partners' /></a></div>
            <div className="header-menu-item"><Link to={Intl.messages['currentLocaleLink'] + 'about/'}><FormattedMessage id='about' /></Link></div>
            <div className="header-menu-item"><Link to={Intl.messages['currentLocaleLink'] + 'contacts/'}><FormattedMessage id='contacts' /></Link></div>
            <div className="header-menu-subitem"><Link to={Intl.messages['currentLocaleLink'] + 'howitworks/'}><FormattedMessage id='how_it_works' /></Link></div>
            <div className="header-menu-bottom">
                <div><a href={'mailto:' + Intl.messages['mail_adress']}><FormattedMessage id='mail_adress' /></a></div>
                <div><a href={'tel:' + Intl.messages['telephone_number']}><FormattedMessage id='telephone_number' /></a></div>
            </div>
        </div>
    );
}
