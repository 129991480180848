import './Header.scss';

import React, { useState } from "react";
import { useIntl, FormattedMessage } from 'react-intl';
import { Link } from "react-router-dom";

import Menu from "./Menu";

import logo from '../assets/logo.png';
import menu from '../assets/menu.png';
import phonePic from '../assets/phone.png';
import emailPic from '../assets/empty-email.png';
import telegramPic from '../assets/telegram.png';
import whatsappPic from '../assets/whatsapp.png';

export default function Header (props)  {

    const [isMenuOpened, setIsMenuOpened] = useState(false);
    const phoneNumber = '+79160406784';
    const whatsappLink = `https://wa.me/${phoneNumber}`;
    const telegramLink = `https://t.me/${phoneNumber}`;

    let headerClassName;
    if (props.searchPage) { headerClassName = 'searchHeader'; }
    if (props.staticPage) { headerClassName = 'staticHeader'; }

    const Intl = useIntl();

    function changeIsMenuOpened( boolean ){
        if (boolean) {
            document.body.style.overflow = "hidden";
        } else {
            document.body.style.overflow = "scroll";
        }
        setIsMenuOpened( boolean );
    }

    return (
        <header className={ headerClassName }>
            <div className="modal-window" style={{ display: isMenuOpened ? 'block' : 'none' }} onClick={ () =>  changeIsMenuOpened(!isMenuOpened) } />
            { isMenuOpened &&
                <Menu
                    changeIsMenuOpened = { changeIsMenuOpened }
                />
            }
            <div className='logo'>
                <div><img className='hamburger_mobile' src={ menu }  alt={ Intl.messages['menu'] } onClick={ () => changeIsMenuOpened(!isMenuOpened) }/></div>
                <div><Link to={ useIntl().messages['currentLocaleLink'] }><img className='logo_icon' src={logo} alt="Najet" /></Link></div>
                { props.searchPage
                    ?
                    <>
                        <div className="text full-text"><FormattedMessage id='first_service' /></div>
                        <div className="text short-text" dangerouslySetInnerHTML={ {__html: Intl.messages['contact_us']} } />
                    </> : ''}
            </div>
            <div className='menu'>
                {/* <span className='contacts'>
                    <Link to={ useIntl().messages['currentLocaleLink'] + 'contacts/' } >
                        <FormattedMessage id='contacts' />
                    </Link>
                </span>
                <span className='telephone'><a href={ 'tel:' + Intl.messages['telephone_number'] }><FormattedMessage id='telephone_number' /></a></span> */}
                <span className='contact-buttons'>
                    <a href={ 'tel:' + Intl.messages['telephone_number'] }>
                        <div className="phone-link">
                            <img className="link-image" src={ phonePic } alt="Phone"/>
                        </div>
                    </a>
                    <a target="_blank" href={ whatsappLink }>
                        <div className="whatsapp-link">
                            <img className="link-image" src={ whatsappPic } alt="Whatsapp"/>
                        </div>
                    </a>
                    <a target="_blank" href={ telegramLink }>
                        <div className="telegram-link">
                            <img className="link-image" src={ telegramPic } alt="Telegram"/>
                        </div>
                    </a>
                    <a href="mailto:info@najet.ru">
                        <div className="email-link">
                            <img className="link-image" src={ emailPic } alt="Email"/>
                        </div>
                    </a>
                </span>
                <span className='hamburger_desktop'><img src={ menu } alt={ Intl.messages['menu'] } onClick={ () => changeIsMenuOpened(!isMenuOpened) } /></span>
                {/* <span className='locale'>
                    <Link to={ useIntl().messages['localeLink'] }>
                        <FormattedMessage id='localeText' />
                    </Link>
                </span> */}
            </div>
        </header>
    );
}
