import { FormattedDate, FormattedMessage, FormattedTime, useIntl } from "react-intl";
import React, { useEffect, useState, Fragment } from "react";

import Skeleton from "react-loading-skeleton";
import ImageGallery from 'react-image-gallery';

import favorites from "../assets/favorites.png";

import './FlightInfo.scss';
import "../../node_modules/react-image-gallery/styles/scss/image-gallery.scss";

import GetPlanePictures from "./GetPlanePictures";
import FilterButton from "./FilterButton";
import Book from "./Book";
import InfoLine from "./InfoLine";
import dateWithoutTimeZone from "./DateWithoutTimeZone";

import returnPicActive from "../assets/return-pic-active.svg";
import returnPic from "../assets/return-pic.svg";

import { PATH } from "../config";

export default function FlightInfo ( props ) {

    const [plane1Pictures, setPlane1Pictures] = useState(null);
    const [plane2Pictures, setPlane2Pictures] = useState(null);

    const [isBookOpen, setIsBookOpen] = useState(false);
    const [error, setError] = useState(null);

    const Intl = useIntl();

    let isFlightInfoLoading;
    let scrollMoveResults = 50;

    // Установка цены
    let price;

    if (props.flightInfo?.routes[1]) {
        price =  props.flightInfo.routes[0].price[ props.currency ] + props.flightInfo.routes[1].price[ props.currency ];
    } else {
        price = props.flightInfo.routes[0].price[ props.currency ];
    }

    if ( props.currency === 'rub') { price = Intl.formatNumber(price) + ' ₽'; }
    if ( props.currency === 'usd') { price = Intl.formatNumber(price) + ' $'; }
    if ( props.currency === 'eur') { price = Intl.formatNumber(price) + ' €'; }

    // Получение фоток для самолета при первом запуске
    useEffect(() => {
        GetPlanePictures( {
            aircraft: props.flightInfo.routes[0].aircraft,
            setPlanePictures : setPlane1Pictures,
            setError : setError,
        });
    }, [])

    // isFlightInfoLoading = true;
    // Если в загруженных данных не совпадает дата, или количество пассажиров с данными запроса, включаем режим загрузки
    let flightInfoDepartureDate = new Date(props.flightInfo.routes[0].departure_date_local);
    if (
        ( props.flightsData && props.isLoading ) &&
        (( props.pax.toString() !== props.flightInfo.routes[0].pax ) || ( props.departureDate.toLocaleString() !== flightInfoDepartureDate.toLocaleString() ))
    ) {
        isFlightInfoLoading = true;
        // console.log('Сравнение props.pax: ' + props.pax + ' и props.flightInfo.routes[0].pax: ' + props.flightInfo.routes[0].pax);
        // console.log('Сравнение props.departureDate: ' + props.departureDate.toLocaleString() + ' и flightInfoDepartureDate: ' + flightInfoDepartureDate.toLocaleString());
        // console.log('Загрузка новых данных flightInfo')
    }

    // Если самолет поменяли, то перезапрашиваем фотки. Загружаем фотки 2-го самолета, для обратного маршрута, если они разные.
    if ( plane1Pictures ) {
        if ( !plane1Pictures[0].original.includes( props.flightInfo.routes[0].aircraft )) {
            GetPlanePictures( {
                aircraft: props.flightInfo.routes[0].aircraft,
                setPlanePictures : setPlane1Pictures,
                setError : setError,
            });
        } else {
            if (props.flightInfo?.routes[1]) {
                if ((!plane2Pictures || !plane2Pictures[0].original.includes( props.flightInfo.routes[1].aircraft )) && props.flightInfo.routes[0].aircraft === props.flightInfo.routes[1].aircraft) { setPlane2Pictures(plane1Pictures) }
                if ((!plane2Pictures || !plane2Pictures[0].original.includes( props.flightInfo.routes[1].aircraft )) && props.flightInfo.routes[0].aircraft !== props.flightInfo.routes[1].aircraft) {
                    GetPlanePictures( {
                        aircraft: props.flightInfo.routes[1].aircraft,
                        setPlanePictures: setPlane2Pictures,
                        setError: setError,
                    });
                }
            }
        }
    }

    // Если переход из поисковой выдачи, ставим текущую дату в описании предложения
    let orderDate;
    if ( props.flightInfo.datetime ) {
        orderDate = new Date( props.flightInfo.datetime );
    } else {
        orderDate = new Date;
    }

    function isBookOpenToggle( boolean ) {
        if (boolean) {
            setIsBookOpen(true);
            document.body.style.overflow = "hidden";
        } else {
            setIsBookOpen(false);
            document.body.style.overflow = "scroll";
        }
    }

    function resultsRightHandler() {
        document.getElementById('results').scrollBy(scrollMoveResults,0);
        document.getElementById("flightinfo-results-left-arrow").setAttribute("style", "display: block");
        if (document.getElementById("results").scrollLeft % scrollMoveResults !== 0) {
            document.getElementById("flightinfo-results-right-arrow").setAttribute("style", "display: none");
        }
    }

    function resultsLeftHandler() {
        document.getElementById('results').scrollBy(-scrollMoveResults,0);
        document.getElementById("flightinfo-results-right-arrow").setAttribute("style", "display: block");
        if (document.getElementById("results").scrollLeft === 0) {
            document.getElementById("flightinfo-results-left-arrow").setAttribute("style", "display: none");
        }
    }

    const OrderInfo = ( props ) => {
        return(
            <div className="flightinfo-info-digits-content-order-info">
                <div className="flightinfo-info-digits-content-order-info-label">
                    <FormattedMessage id="order_info_label" />{ props.flightInfo.number }
                </div>
                <div className="flightinfo-info-digits-content-order-info-text">
                    <FormattedMessage id="order_info_text_1" />
                    { orderDate.toLocaleString("ru", {  hour: 'numeric', minute: 'numeric', day: '2-digit', month: '2-digit', year: 'numeric'}) }
                    <FormattedMessage id="order_info_text_2" />
                </div>
            </div>
        );
    }

    const PlaneSpecs = ( props ) => {
        return(
            <div>
                <div className="flightinfo-info-digits-content-specs"><FormattedMessage id='specs' /></div>
                <div className="flightinfo-info-digits-content-specs-grid">
                    <div className="flightinfo-info-digits-content-specs-grid-item">
                        <div className="flightinfo-info-digits-content-specs-grid-item-digit">{ props.flightInfo.characteristics.flying_range } <FormattedMessage id='range_spec' /></div>
                        <div className="flightinfo-info-digits-content-specs-grid-item-label"><FormattedMessage id='range' /></div>
                    </div>
                    <div className="flightinfo-info-digits-content-specs-grid-item">
                        <div className="flightinfo-info-digits-content-specs-grid-item-digit">{ props.flightInfo.characteristics.cruising_speed } <FormattedMessage id='speed_spec' /></div>
                        <div className="flightinfo-info-digits-content-specs-grid-item-label"><FormattedMessage id='speed' /></div>
                    </div>
                    <div className="flightinfo-info-digits-content-specs-grid-item">
                        <div className="flightinfo-info-digits-content-specs-grid-item-digit"><FormattedMessage id='seats' values={{ count: props.flightInfo.max_pax }}/></div>
                        <div className="flightinfo-info-digits-content-specs-grid-item-label"><FormattedMessage id='max_pax' /></div>
                    </div>
                    <div className="flightinfo-info-digits-content-specs-grid-item">
                        <div className="flightinfo-info-digits-content-specs-grid-item-digit">{ props.flightInfo.aircraft_year_of_creation }</div>
                        <div className="flightinfo-info-digits-content-specs-grid-item-label"><FormattedMessage id='year_made' /></div>
                    </div>
                </div>
            </div>
        );
    }

    function RenderFlightInfo ( props ) {

        let planeAvatar = { backgroundImage: 'url(' + PATH.PICTURES + props.flightInfo.aircraft_picture_urls.avatar + ')' };
        let planeSalon = { background: 'url(' + PATH.PICTURES + props.flightInfo.aircraft_picture_urls.seats + ') center bottom no-repeat, #F9F7F1 linear-gradient(180deg, rgba(255, 255, 255, 0.96) 58.86%, rgba(255, 255, 255, 0) 94.14%)' };

        let flightinfoInfoDigitsClass;
        let flightinfoInfoPicturesWrapperClass;

        let planePictures;

        if (props.direction === 'back') {
            flightinfoInfoDigitsClass = 'flightinfo-info-digits flightinfo-info-digits-back';
            flightinfoInfoPicturesWrapperClass = 'flightinfo-info-pictures-wrapper flightinfo-info-pictures-wrapper-back';
            planePictures = plane2Pictures;
        } else {
            flightinfoInfoDigitsClass = 'flightinfo-info-digits';
            flightinfoInfoPicturesWrapperClass = 'flightinfo-info-pictures-wrapper';
            planePictures = plane1Pictures;
        }

        let showBook = isBookOpen && props.direction === 'there';

        return (
            <Fragment>
                { showBook &&
                    <Book
                        isBookOpenToggle = { isBookOpenToggle }
                        flightInfo = { props.flightInfo }
                        cardId = { props.cardId }
                    />
                }
                <div className="flightinfo-label">
                    <div className="flightinfo-label-caption">
                        { props.departureBackDate &&
                            <div className="flightinfo-label-caption-direction">
                                { props.direction === 'there' ? <FormattedMessage id='there' /> : <FormattedMessage id='back' /> }
                            </div>
                        }
                        <div>{ props.flightInfo.aircraft_type }</div>
                        <div className="flightinfo-label-splitter">&nbsp;·&nbsp;</div>
                        <div>
                            { !isFlightInfoLoading? <FormattedDate value={ dateWithoutTimeZone(props.flightInfo.departure_date_local) } day='numeric' month='long'  /> : <Skeleton className="skeleton" /> }
                            { !isFlightInfoLoading && <span>, в&nbsp;</span> }
                            { !isFlightInfoLoading? <FormattedTime value={ dateWithoutTimeZone(props.flightInfo.departure_date_local) } /> : null }
                        </div>
                    </div>
                    { props.direction === 'there' &&
                        <div className="flightinfo-label-buttons">
                            <FilterButton
                                label = { Intl.messages['return_flight'] }
                                leftIcon = { props.departureBackDate ? returnPicActive : returnPic }
                                style = { 'flightinfo-label-buttons-return' }
                                action = { () => props.returnButtonHandler() }
                            />
                            <div className="flightinfo-label-buttons-return-favorites"><img src={ favorites } alt={ Intl.messages['favorites'] } /></div>
                        </div>
                    }
                    <div className="flightinfo-label-specs">
                        <div className="flightinfo-label-specs-digits">{ props.flightInfo.aircraft_year_of_creation }</div>
                        <div className="flightinfo-label-specs-digits"><FormattedMessage id='seats' values={{ count: props.flightInfo.max_pax }}/></div>
                        <div className="flightinfo-label-specs-plane-type">{ props.flightInfo.aircraft_class }</div>
                    </div>
                </div>
                <div className="flightinfo-info">
                    <div className={ flightinfoInfoDigitsClass } >
                        <div className="flightinfo-info-digits-content">
                            <div className="flightinfo-info-digits-content-picture" style={ planeAvatar }>
                                <FormattedMessage id='fly'/>&nbsp;
                                { props.averageFlightTime &&
                                    <Fragment>
                                        <FormattedMessage id='hours' values={{ count: props.averageFlightTime.getHours() }}/>&nbsp;
                                        <FormattedMessage id='minutes' values={{ count: props.averageFlightTime.getMinutes() }}/>
                                    </Fragment>
                                }
                            </div>
                            <div className="flightinfo-info-digits-content-line"/>
                            <PlaneSpecs
                                flightInfo = { props.flightInfo }
                            />
                            <OrderInfo
                                flightInfo = { props.flightInfo }
                            />
                        </div>
                    </div>
                    <div className={ flightinfoInfoPicturesWrapperClass }>
                        <div className='gallery'>
                            { planePictures &&
                                <ImageGallery
                                    items = { planePictures }
                                    showThumbnails = { false }
                                    showPlayButton = { false }
                                    showBullets = { true }
                                />
                            }
                        </div>
                    </div>
                </div>
                { props.direction === 'there' &&
                    <div className="flightinfo-infoline" id="flightinfo-infoline">
                        <div className="flightinfo-infoline-price">
                            { !isFlightInfoLoading ? price : <Skeleton className="skeleton"/> }
                        </div>
                        <div className="flightinfo-infoline-big-button flightinfo-infoline-order" onClick={() => isBookOpenToggle(true)}>
                            <div className="flightinfo-infoline-big-button-label"><FormattedMessage id='order'/></div>
                        </div>
                        <InfoLine />
                    </div>
                }
                <div className="flightinfo-mobileinfo">
                    <PlaneSpecs
                        flightInfo = { props.flightInfo }
                    />
                    <hr/>
                    <OrderInfo
                        flightInfo = { props.flightInfo }
                    />
                </div>
                <div style={ planeSalon } className="flightinfo-salon">
                    <div className="flightinfo-salon-label"><FormattedMessage id="salon_label" /></div>
                    <div className="flightinfo-salon-grid">
                        <div className="flightinfo-salon-grid-item">
                            <div className="flightinfo-salon-grid-item-label"><FormattedMessage id="salon_height_text" /></div>
                            <div className="flightinfo-salon-grid-item-digit">
                                { props.flightInfo.characteristics.cabin_height }&nbsp;
                                <FormattedMessage id="spec_meters" />
                            </div>
                        </div>
                        <div className="flightinfo-salon-grid-item">
                            <div className="flightinfo-salon-grid-item-label"><FormattedMessage id="wings_text" /></div>
                            <div className="flightinfo-salon-grid-item-digit">
                                { props.flightInfo.characteristics.wing_span }&nbsp;
                                <FormattedMessage id="spec_meters" />
                            </div>
                        </div>
                        <div className="flightinfo-salon-grid-item">
                            <div className="flightinfo-salon-grid-item-label"><FormattedMessage id="salon_length_text" /></div>
                            <div className="flightinfo-salon-grid-item-digit">
                                { props.flightInfo.characteristics.cabin_length }&nbsp;
                                <FormattedMessage id="spec_meters" />
                            </div>
                        </div>
                        <div className="flightinfo-salon-grid-item">
                            <div className="flightinfo-salon-grid-item-label"><FormattedMessage id="height_text" /></div>
                            <div className="flightinfo-salon-grid-item-digit">
                                { props.flightInfo.characteristics.fuselage_height }&nbsp;
                                <FormattedMessage id="spec_meters" />
                            </div>
                        </div>
                        <div className="flightinfo-salon-grid-item">
                            <div className="flightinfo-salon-grid-item-label"><FormattedMessage id="salon_width_text" /></div>
                            <div className="flightinfo-salon-grid-item-digit">
                                { props.flightInfo.characteristics.interior_width }&nbsp;
                                <FormattedMessage id="spec_meters" /></div>
                        </div>
                        <div className="flightinfo-salon-grid-item">
                            <div className="flightinfo-salon-grid-item-label"><FormattedMessage id="length_text" /></div>
                            <div className="flightinfo-salon-grid-item-digit">
                                { props.flightInfo.characteristics.fuselage_length }&nbsp;
                                <FormattedMessage id="spec_meters" />
                            </div>
                        </div>
                    </div>
                    <div className="flightinfo-salon-img">
                        <img src={ PATH.PICTURES + props.flightInfo.aircraft_picture_urls.seats } alt={ Intl.messages['salon'] } />
                    </div>
                </div>
            </Fragment>
        );
    }

    return (
        <Fragment>
            { props.departureBackDate &&
                <div className="background-wrapper" />
            }
            <div className={ props.departureBackDate ? 'flightinfo flightinfo-there' : 'flightinfo'}>
                <div className="result-arrows">
                    <div className="left-arrow" onClick={ resultsLeftHandler } id="flightinfo-results-left-arrow" />
                    { document.getElementById('results').clientWidth <  document.getElementById('results').scrollWidth &&
                        <div className="right-arrow" onClick={ resultsRightHandler } id="flightinfo-results-right-arrow" />
                    }
                </div>
                <RenderFlightInfo
                    cardId = { props.flightInfo.id }
                    flightInfo = { props.flightInfo.routes[0] }
                    departureBackDate = { props.departureBackDate }
                    returnButtonHandler = { props.returnButtonHandler }
                    averageFlightTime = { props.averageFlightTime?.there }
                    direction = { 'there' }
                    pax = { props.pax }
                    paxLessChangeHandler = { props.paxLessChangeHandler }
                    paxMoreChangeHandler = { props.paxMoreChangeHandler }
                />
            </div>
            { (props.departureBackDate && props.flightInfo.routes[1]) &&
                <div className="flightinfo flightinfo-back">
                    <RenderFlightInfo
                        flightInfo = { props.flightInfo.routes[1] }
                        departureBackDate = { props.departureBackDate }
                        averageFlightTime = { props.averageFlightTime?.back }
                        direction = { 'back' }
                    />
                </div>
            }
        </Fragment>
    );
}
