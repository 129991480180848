import { PATH } from "../config";
import isoDateWithoutTimeZone from "./IsoDateWithoutTimeZone";
import ym from 'react-yandex-metrika';

export default function GetFlightsData ( requestParams, setFlightsData, setFlightInfo, setIsLoading, setError ) {
    let thisRequestParams = Object.assign({},requestParams);
    let flightsData;
    let flightInfo;
    let url;

    if ( thisRequestParams.request_id ) { url = new URL( PATH.FLIGHTS_BY_ID ) + requestParams.request_id; }
    if ( thisRequestParams.card_id ) { url = new URL( PATH.CARD_BY_ID ) + requestParams.card_id; }
    if ( thisRequestParams.departure_date_there )
    {
        thisRequestParams.departure_date_there = isoDateWithoutTimeZone(thisRequestParams.departure_date_there);
        if ( thisRequestParams.departure_date_back ) { thisRequestParams.departure_date_back = isoDateWithoutTimeZone(thisRequestParams.departure_date_back); }
        url = new URL( PATH.FLIGHTS );
        url.search = new URLSearchParams(thisRequestParams).toString();
    }
    if ( thisRequestParams.emptyLeg ) {
        delete thisRequestParams.emptyLeg;
        thisRequestParams.pax_there = 1;
        url = new URL( PATH.EMPTY_LEGS );
        url.search = new URLSearchParams(thisRequestParams).toString();
    }

    fetch( url )
        .then(response => response.json())
        .then(
            (result) => {
                if ( thisRequestParams.card_id ) {
                    flightInfo = result
                    setFlightInfo( flightInfo );
                    // console.log( 'Данные по предложению получены в GetFlightsData: ' + flightInfo );
                    return true;
                } else {
                    flightsData = result
                    setFlightsData( flightsData );
                    setIsLoading( false );
                    // Отправка информации в метрику
                    if (flightsData) {
                        if (flightsData.cards.length > 0) {
                            ym('reachGoal','SEARCH_RESULTS');
                        } else {
                            ym('reachGoal','EMPTY_SEARCH');
                        }
                    }
                    // console.log( 'Данные по рейсам получены в GetFlightsData: ' + flightsData );
                    return true;
                }
            },
            (error) => {
                return setError(error);
            });
}