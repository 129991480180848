import './SlickSlider.scss';

import React, { Component } from "react";
import { injectIntl } from 'react-intl';
import Slider from "react-slick";
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

class SlickSlider extends Component {
    render() {
        const { intl } = this.props;
        var settings = {
            className: "",
            dots: false,
            infinite: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            adaptiveHeight: true,
            autoplay: true,
            speed: 1500,
            autoplaySpeed: 5000,
            centerPadding: "10px",
        };
    return (
        <div>
            <Slider {...settings}>
                <div className="slide-item" >
                    <div className="slide-item-inner">
                        <p className="item-text" dangerouslySetInnerHTML={ {__html: intl.messages['main_subtitle']} } />
                    </div>
                </div>
            </Slider>
        </div>
    )}
}
export default injectIntl(SlickSlider);
